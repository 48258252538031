import React from "react";
import "./footer.css";
import {
  FaGithub,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

export const Footer = () => {
  return (
    <div className="stick_follow_icon">
      <p>Follow Me</p>
      <ul>
        <li><a href="https://twitter.com/roynakakawa" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
        <li><a href="https://github.com/roynakakawa" target="_blank" rel="noopener noreferrer"><FaGithub /></a></li>
        <li><a href="https://www.linkedin.com/in/miguel-r-nakakawa/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
      </ul>
    </div>   
  );
};
