import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes';
import { Header } from './components/header';
import { Footer } from './components/footer';
import AnimatedCursor from 'react-animated-cursor';
import { BrowserView } from 'react-device-detect';

export default function App() {
  return (

    <Router>
      <BrowserView>
          <AnimatedCursor 
            innerSize={10}
            outerSize={20}
            color='102, 187, 106'
            outerAlpha={0.3}
            innerScale={0.4}
            outerScale={2.5}
          />
      </BrowserView>
      <Header />
      <AppRoutes />
      <Footer />
    </Router>

  );
}
