import React from "react";
import "./home.css";
import Typewriter from "typewriter-effect";
import { meta } from '../../seo_meta';
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import myimg from "../../assets/roy.png"

const today = new Date();

function formatDate(date) {
  return new Intl.DateTimeFormat(
    'en-US',
    { weekday: 'long' }
  ).format(date);
}

export const Home = () => {
  return (
    <div className="home_class">
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
        <div className="intro_sec d-lg-flex">
         <div className="text">
            <div className="intro">
                <h1>Hello there, happy {formatDate(today)}!</h1>
                <h1 className="mb-5">I'm <strong>Roy Nakakawa</strong></h1>
                <h2 className="mb-5">
                  <Typewriter
                    options={{
                      strings: [
                        "I'm thrilled by Blockchain",
                        "I want to decentralize the money",
                        "I have a big passion for Crypto",
                      ],
                      autoStart: true,
                      loop: true,
                      delay: 50,
                      pauseFor: 2500,
                      deleteSpeed: 10,
                    }}
                  />
                </h2>
                <p className="mb-3">Maybe you have an idea or a plan ...</p>
                <p className="mb-3">You have heard 'crypto' or 'blockchain' but don't know where to start?</p>
                <p className="mb-3">I can help you !!</p>
                <p className="mb-5">Don't worry, I don't charge a fixed fee. I mostly receive donations for my work</p>
                <NavLink to="/contact">
                  <button className="btn-class mb-5">contact me</button>
                </NavLink>
            </div>
         </div>
         <div className="prof_image" style={{backgroundImage:`url(${myimg})`}}/>
        </div>
      </div>
  );
};