import React, {useRef, useState} from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "./contact.css";
import { Helmet } from "react-helmet";
import { meta } from '../../seo_meta';
import emailjs from "@emailjs/browser";

export const ContactMe = () => {

  const [formValues, setformValues] = useState({
    name:"",
    email:"",
    message: "",
    captcha:"",
  });

  const [alertpop, setAlertpop] = useState({
    show: false,
    alertheading:"",
    alertmessage:"",
    variant: "",
  });

  const handleChange = (e) => {
        setformValues({...formValues, [e.target.name]: e.target.value,});
  };

  const sendEmail = (e) => {
    e.preventDefault();
    
    if(formValues.captcha !== '18') {
      setAlertpop({
        show: true,
        alertheading: "Error",
        alertmessage:"Math is not mathing ...",
        variant: "danger",
      });
      return;
    }

    if(formValues.name.length < 2 || formValues.name.length > 20 ) {
      setAlertpop({
        show: true,
        alertheading: "Error",
        alertmessage:"Name doesn't seems to be right (2-20 chars)",
        variant: "danger",
      });
      return;
    }

    if(formValues.email.length < 6 || formValues.email.length > 30 ) {
      setAlertpop({
        show: true,
        alertheading: "Error",
        alertmessage:"Email doesn't seems to be right",
        variant: "danger",
      });
      return;
    }

    if(formValues.message.length < 2 || formValues.message.length > 200 ) {
      setAlertpop({
        show: true,
        alertheading: "Error",
        alertmessage:"Message cannot be less than 2 or more than 200 chars",
        variant: "danger",
      });
      return;
    }

    setAlertpop({
      show: true,
      alertmessage:"Sending ...",
      variant: "info",
    });

    const tempData = {
      user_name: formValues.name,
      user_email: formValues.email,
      message: formValues.message,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        tempData,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setAlertpop({
            show: true,
            alertheading: "Sent",
            alertmessage:"Thanks for your message",
            variant: "success",
          });
          document.getElementById("contact-form").reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

    return(
    <div className="contact_class">
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <div className="contact_container"/>
       <Container>
          <Row className="mb-lg-3 pb-lg-5">
            <Col lg="9">
              <h1>Contact Me</h1>
              <hr className="t_border my-4 text-left" />
            </Col>
          </Row>
          <form id="contact-form" className="pb-4" onSubmit={sendEmail}>
            <Row className="mb-2">
              <Col lg="2">
                <p>Your name:</p>
              </Col>
              <Col>
                <input 
                  className="form-class" 
                  type="text"
                  name="name"
                  id="name" 
                  placeholder="Name" 
                  autoComplete="on"
                  maxLength="20"
                  onChange={handleChange}
                  required/>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="2">
                <p>Your email:</p>
              </Col>
              <Col lg="5">
              <input 
                className="form-class" 
                type="email"
                name="email" 
                id="email"
                placeholder="Email" 
                autoComplete="on"
                maxLength="30"
                onChange={handleChange}
                required/>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="2">
                <p>3 x 6:</p>
              </Col>
              <Col lg="5">
                <input 
                className="form-class" 
                type="text"
                name="captcha" 
                id="captcha"
                placeholder="??" 
                autoComplete="off"
                maxLength="4"
                onChange={handleChange}
                required/>
              </Col>
            </Row>
            <Row>
                <p>Your message:</p>
            </Row>
            <Row className="mb-4">
              <Col>
                <textarea 
                className="message-class"
                name="message" 
                id="message"
                placeholder="Message" 
                maxLength="200"
                onChange={handleChange}
                required/>
              </Col>
            </Row>
            <button className="btn-class mb-4" type="submit">send</button>
            <Row>
              <Col lg="5">
                {alertpop.show &&
                  <Alert 
                  variant={alertpop.variant}
                  onClose={() => setAlertpop({show: false})} dismissible>   
                  <Alert.Heading>{alertpop.alertheading}</Alert.Heading> 
                  <p>{alertpop.alertmessage}</p> 
                  </Alert> 
                }
              </Col>
            </Row>
          </form>
        </Container>
    </div>
    );
};

